<script>
import { VToolbar } from 'vuetify/lib';

/* @vue/component */
export default VToolbar.extend({
  name: 'sm-improved-toolbar',
  props: {
    bottom: Boolean,
  },
  computed: {
    classes () {
      return {
        'v-toolbar': true,
        'elevation-0': this.flat || (
          !this.isActive &&
          !this.tabs &&
          !this.isExtended &&
          this.canScroll
        ),
        'v-toolbar--absolute': this.absolute,
        'v-toolbar--card': this.card,
        'v-toolbar--clipped': this.clippedLeft || this.clippedRight,
        'v-toolbar--dense': this.dense,
        'v-toolbar--extended': this.isExtended,
        'v-toolbar--fixed': !this.absolute && (this.app || this.fixed),
        'v-toolbar--floating': this.floating,
        'v-toolbar--prominent': this.prominent,
        'v-toolbar--top': !this.bottom,
        'v-toolbar--bottom': this.bottom,
        ...this.themeClasses,
      };
    },
    computedTransform () {
      if (this.isActive || !this.canScroll) return 0;
      const multiplier = this.bottom ? 1 : -1;
      // return multiplier * (this.canScroll ? this.computedHeight : 0);
      return multiplier * (this.computedContentHeight + (!this.isExtended ? this.safeArea : 0));
    },
    safeArea() {
      return parseInt(getComputedStyle(document.documentElement).getPropertyValue(`--safe-area-${this.bottom ? 'bottom' : 'top'}`).replaceAll('px', ''));
    },
  },
  watch: {
    currentThreshold (val) {
      if (this.invertedScroll) {
        this.isActive = this.currentScroll > this.scrollThreshold;
        return;
      }

      if ((!this.isScrollingUp && val < this.scrollThreshold) || !this.isBooted) return;

      this.isActive = this.isScrollingUp;
      this.savedScroll = this.currentScroll;
    },
  },
  render (h) {
    this.isExtended = this.extended || !!this.$slots.extension;

    const children = [];
    const data = this.setBackgroundColor(this.color, {
      // eslint-disable-next-line quote-props
      'class': this.classes,
      style: this.styles,
      on: this.$listeners,
    });

    data.directives = [
      {
        arg: this.scrollTarget,
        name: 'scroll',
        value: this.onScroll,
      },
    ];

    children.push(h('div', {
      staticClass: 'v-toolbar__content',
      style: { height: `${this.computedContentHeight}px` },
      ref: 'content',
    }, this.$slots.default));

    if (this.isExtended) {
      children.push(h('div', {
        staticClass: 'v-toolbar__extension pa-0',
        style: { height: `${this.computedExtensionHeight}px` },
      }, this.$slots.extension));
    }

    const navContent = [];
    navContent.push(h('div', { staticClass: 'v-toolbar-before', style: 'z-index: 99999' }));
    navContent.push(h('nav', data, children));

    return h('div', {}, navContent);
  },
});
</script>

<style lang="scss" scoped>
.v-toolbar-before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--safe-area-top);
  background: white;
}
.v-toolbar {
  background: white;
  transition: transform 1s;
  overflow: hidden;

  &.v-toolbar--bottom {
    box-shadow: 0 -2px 4px -1px rgba(0,0,0,.2), 0 -4px 5px 0 rgba(0,0,0,.14), 0 -1px 10px 0 rgba(0,0,0,.12);
  }

  .v-toolbar__content {
    padding: 0;
  }

  &.v-toolbar--extended .v-toolbar__content {
    border-bottom: 1px solid var(--StarlingLightGrey);
  }

  &.v-toolbar--absolute,
  &.v-toolbar--fixed {
    &.v-toolbar--top {
      padding-top: var(--safe-area-top);
    }
    &.v-toolbar--bottom {
      top: auto;
      bottom: 0;
      padding-bottom: var(--safe-area-bottom);
    }
  }
}
</style>
